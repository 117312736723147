
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/config";

export default defineComponent({
  name: "howWorks",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageTitle("How works");
    });
  },
});
